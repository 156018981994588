import { createBrowserWindow } from "@messenger/util/helpers";
import { IS_BUSINESS_APP } from "../config";

export function redirectToCall(chatId: string, isJoin: boolean = false) {
  if (window.electron) {
    if (isJoin) {
      createBrowserWindow(
        location.origin +
          (IS_BUSINESS_APP ? "/app" : "") +
          "/calls/join/" +
          chatId,
        "Tawasal Group Call",
      );
    } else {
      createBrowserWindow(
        location.origin + (IS_BUSINESS_APP ? "/app" : "") + "/calls/" + chatId,
        "Tawasal Group Call",
      );
    }
  } else {
    if (isJoin) {
      window.location.assign(
        (IS_BUSINESS_APP ? "/app" : "") + "/calls/join/" + chatId,
      );
    } else {
      window.location.assign(
        (IS_BUSINESS_APP ? "/app" : "") + "/calls/" + chatId,
      );
    }
  }
}
