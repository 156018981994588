export function createBrowserWindow(url: string, title?: string) {
  window.electron?.openNewWindow(url, title);
}

export function closeWindow() {
  window.electron?.closeWindow();
}

export function blobToDataURL(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result as string);
      } else {
        reject(new Error("Failed to convert Blob to Data URL"));
      }
    };

    reader.onerror = () => reject(reader.error);
    reader.readAsDataURL(blob);
  });
}
