import { createStore } from "zustand";
import { useEffect, useState } from "@messenger/lib/teact/teact";
import { Api } from "@calls/lib/gramjs";

export type Store = {
  needCaptcha: boolean;
  request: null | Api.AnyRequest;
};

export const MTProtoStore = createStore<Store>(() => ({
  needCaptcha: false,
  request: null,
}));

export const useMTProtoStore = () => {
  const [state, dispatch] = useState<Store>(MTProtoStore.getState());
  useEffect(() => {
    MTProtoStore.subscribe((state, prevState) => {
      dispatch(state);
    });
  });

  return { ...state };
};
