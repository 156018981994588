import { addActionHandler, getGlobal, setGlobal } from "@messenger/global";

import {
  ANIMATION_LEVEL_MAX,
  appsConfig,
} from "@messenger/config";
import {
  IS_ANDROID,
  IS_IOS,
  IS_MAC_OS,
  IS_SAFARI,
  IS_TOUCH_ENV,
} from "@messenger/util/environment";
import { setLanguage } from "@messenger/util/langProvider";
import switchTheme from "@messenger/util/switchTheme";
import { selectTheme, selectUser } from "@messenger/global/selectors";
// import { startWebsync } from '@messenger/util/websync';
import { ensurePermanentWebVersion } from "@messenger/util/permanentWebVersion";
import { ThemeKey } from "@@/src/app/types";

const HISTORY_ANIMATION_DURATION = 450;

subscribeToSystemThemeChange();

addActionHandler("init", (global) => {
  const { animationLevel, messageTextSize, language } = global.settings.byKey;
  let theme = selectTheme(global);
  void setLanguage(language, undefined, true);

  document.documentElement.style.setProperty(
    "--composer-text-size",
    `${Math.max(messageTextSize, IS_IOS ? 16 : 15)}px`,
  );
  document.documentElement.style.setProperty(
    "--message-meta-height",
    `${Math.floor(messageTextSize * 1.3125)}px`,
  );
  document.documentElement.style.setProperty(
    "--message-text-size",
    `${messageTextSize}px`,
  );
  document.documentElement.setAttribute(
    "data-message-text-size",
    messageTextSize.toString(),
  );
  document.body.classList.add("initial");
  document.body.classList.add(`animation-level-${animationLevel}`);
  document.body.classList.add(IS_TOUCH_ENV ? "is-touch-env" : "is-pointer-env");

  theme = theme.toLocaleLowerCase().includes("light")
    ? appsConfig.theme.light
    : appsConfig.theme.dark;


  switchTheme(theme, animationLevel === ANIMATION_LEVEL_MAX);

  ensurePermanentWebVersion();
  // FIXME: in dev mode errors on every reload
  // startWebsync();

  if (IS_IOS) {
    document.body.classList.add("is-ios");
  } else if (IS_ANDROID) {
    document.body.classList.add("is-android");
  } else if (IS_MAC_OS) {
    document.body.classList.add("is-macos");
  }
  if (IS_SAFARI) {
    document.body.classList.add("is-safari");
  }
});

addActionHandler("setIsUiReady", (global, actions, payload) => {
  const { uiReadyState } = payload!;

  if (uiReadyState === 2) {
    document.body.classList.remove("initial");
  }

  return {
    ...global,
    uiReadyState,
  };
});

addActionHandler("setAuthPhoneNumber", (global, actions, payload) => {
  const { phoneNumber } = payload!;

  return {
    ...global,
    authPhoneNumber: phoneNumber,
  };
});

addActionHandler("setAuthRememberMe", (global, actions, payload) => {
  return {
    ...global,
    authRememberMe: Boolean(payload),
  };
});

addActionHandler("clearAuthError", (global) => {
  return {
    ...global,
    authError: undefined,
  };
});

addActionHandler("disableHistoryAnimations", () => {
  setTimeout(() => {
    setGlobal({
      ...getGlobal(),
      shouldSkipHistoryAnimations: false,
    });
    document.body.classList.remove("no-animate");
  }, HISTORY_ANIMATION_DURATION);

  setGlobal(
    {
      ...getGlobal(),
      shouldSkipHistoryAnimations: true,
    },
    { forceSyncOnIOs: true },
  );
});

function subscribeToSystemThemeChange() {
  function handleSystemThemeChange() {
    const currentThemeMatch =
      document.documentElement.className.match(/theme-(\w+)/);
    const currentTheme = currentThemeMatch ? currentThemeMatch[1] : "light";
    const global = getGlobal();
    let nextTheme = selectTheme(global);
    const { animationLevel } = global.settings.byKey;

    nextTheme = (() => {
      return nextTheme.toLocaleLowerCase().includes("light")
        ? appsConfig.theme.light
        : appsConfig.theme.dark;
    })();

    if (nextTheme !== currentTheme) {
      switchTheme(nextTheme, animationLevel === ANIMATION_LEVEL_MAX);
      // Force-update component containers
      setGlobal({ ...global });
    }
  }

  const mql = window.matchMedia("(prefers-color-scheme: dark)");
  if (typeof mql.addEventListener === "function") {
    mql.addEventListener("change", handleSystemThemeChange);
  } else if (typeof mql.addListener === "function") {
    mql.addListener(handleSystemThemeChange);
  }
}