import React, { type FC, memo } from "@messenger/lib/teact/teact";
import { withGlobal } from "@messenger/global";
import { cn } from "@messenger/shared/styles";

import type { LangCode } from "@messenger/types";
import * as styles from "@messenger/components/auth/AuthBrand.module.scss";
import * as authCommonStyles from "@messenger/components/auth/styles.module.scss";

import downloadAndroidPath from "@messenger/assets/download-android.png";
import downloadIosPath from "@messenger/assets/download-ios.png";
import downloadHuaweiPath from "@messenger/assets/download-huawei.png";

import { appsConfig } from "@messenger/config";

enum Download {
  Android = "https://play.google.com/store/apps/details?id=com.tawasul.messenger",
  iOS = "https://apps.apple.com/us/app/tawasal-superapp/id1507761438",
  Huawei = "https://appgallery.huawei.com/app/C105867571",
}

type StateProps = {
  language?: LangCode;
};

const AuthBrand: FC<StateProps> = () => {
  const { name, logo } = appsConfig;
  return (
    <section className={authCommonStyles.halfPageWrapper}>
      <div className={cn(authCommonStyles.halfPageContainer, styles.container)}>
        <div className={styles.logoContainer}>
          <img src={logo} alt={name} />
          <span className={styles.logoTitle}>{name}</span>
        </div>

        <div>
          <h1 className={styles.title}>Join {name}</h1>
          <p className={styles.subtitle}>
            Download {name} SuperApp now and stay in touch. Anytime. Anywhere.
          </p>
        </div>

        {!window.electron && (
          <div className={styles.downloadButtonsWrapper}>
            <a
              href={Download.iOS}
              target="_blank"
              className={styles.downloadButton}
              rel="noreferrer"
            >
              <img src={downloadIosPath} alt={`Download ${name} on iOS`} />
            </a>
            <a
              href={Download.Android}
              target="_blank"
              className={styles.downloadButton}
              rel="noreferrer"
            >
              <img
                src={downloadAndroidPath}
                alt={`Download ${name} on Android`}
              />
            </a>

            <a
              href={Download.Huawei}
              target="_blank"
              className={styles.downloadButton}
              rel="noreferrer"
            >
              <img
                src={downloadHuaweiPath}
                alt={`Download ${name} on Huawei`}
              />
            </a>
          </div>
        )}
      </div>
    </section>
  );
};

export default memo(
  withGlobal((global): StateProps => {
    const {
      settings: {
        byKey: { language },
      },
    } = global;

    return {
      language,
    };
  })(AuthBrand),
);
