import type { NotificationConstructorOptions } from "electron";

export enum ElectronEvent {
  FULLSCREEN_CHANGE = "fullscreen-change",
  UPDATE_ERROR = "update-error",
  UPDATE_AVAILABLE = "update-available",
  DEEPLINK = "deeplink",
}

export enum ElectronAction {
  SHOW_NOTIFICATIONS = "show-notifications",
  SET_COUNTER = "set-counter",
  INSTALL_UPDATE = "install-update",
  GET_IS_FULLSCREEN = "get-is-fullscreen",
  HANDLE_DOUBLE_CLICK = "handle-double-click",
  OPEN_NEW_WINDOW = "open-new-window",
  CLOSE_ME = "close-window",
  SET_WINDOW_TITLE = "set-window-title",
  SET_IS_TRAY_ICON_ENABLED = "set-is-tray-icon-enabled",
  GET_IS_TRAY_ICON_ENABLED = "get-is-tray-icon-enabled",
  RESTORE_LOCAL_STORAGE = "restore-local-storage",
}

export type TrafficLightPosition = "standard" | "lowered";

export interface ElectronApi {
  showNotification: (
    notification: NotificationConstructorOptions,
  ) => Promise<true>;
  installUpdate: () => Promise<void>;
  setCounter: (count: number) => Promise<void>;
  isFullscreen: () => Promise<boolean>;
  handleDoubleClick: () => Promise<void>;
  openNewWindow: (url: string, title?: string) => Promise<void>;
  setWindowTitle: (title?: string) => Promise<void>;
  setIsTrayIconEnabled: (value: boolean) => Promise<void>;
  getIsTrayIconEnabled: () => Promise<boolean>;
  restoreLocalStorage: () => Promise<void>;
  closeWindow: () => Promise<void>;
  on: (eventName: ElectronEvent, callback: any) => () => void;
}

declare global {
  interface Window {
    electron?: ElectronApi;
  }
}
