const { Mutex } = require("async-mutex");

const mutex = new Mutex();

const IS_BUSINESS_APP = location.origin.includes(process.env.BUSINESS_APP_URL)

const WebSocketClient = require("websocket").w3cwebsocket;

const closeError = new Error("WebSocket was closed");

class PromisedWebSockets {
  constructor(disconnectedCallback) {
    /* CONTEST
        this.isBrowser = typeof process === 'undefined' ||
            process.type === 'renderer' ||
            process.browser === true ||
            process.__nwjs

         */
    this.client = undefined;
    this.closed = true;
    this.disconnectedCallback = disconnectedCallback;
  }

  async readExactly(number) {
    let readData = Buffer.alloc(0);

    while (true) {
      const thisTime = await this.read(number);
      readData = Buffer.concat([readData, thisTime]);
      number -= thisTime.length;
      if (!number) {
        return readData;
      }
    }
  }

  async read(number) {
    if (this.closed) {
      throw closeError;
    }
    await this.canRead;
    if (this.closed) {
      throw closeError;
    }
    const toReturn = this.stream.slice(0, number);
    this.stream = this.stream.slice(number);
    if (this.stream.length === 0) {
      this.canRead = new Promise((resolve) => {
        this.resolveRead = resolve;
      });
    }

    return toReturn;
  }

  async readAll() {
    if (this.closed || !(await this.canRead)) {
      throw closeError;
    }
    const toReturn = this.stream;
    this.stream = Buffer.alloc(0);
    this.canRead = new Promise((resolve) => {
      this.resolveRead = resolve;
    });

    return toReturn;
  }

  getWebSocketLink(ip, port, testServers, isPremium) {
    if (IS_BUSINESS_APP) {
      return `wss://${process.env.TAWASAL_T_URL}`;
    }
    return `wss://${process.env.TELEGRAM_T_URL}:8889`;
  }

  connect(port, ip, testServers = false) {
    this.stream = Buffer.alloc(0);
    this.canRead = new Promise((resolve) => {
      this.resolveRead = resolve;
    });
    this.closed = false;
    // this.website = this.getWebSocketLink(ip, port, testServers);
    // this.client = new WebSocketClient(this.website, 'binary');
    this.website = this.getWebSocketLink(ip, port, testServers);
    this.client = new WebSocketClient(this.website);
    this.client.binaryType = "arraybuffer";
    return new Promise((resolve, reject) => {
      this.client.onopen = () => {
        this.receive();
        resolve(this);
      };
      this.client.onerror = (error) => {
        console.error("WebSocket error", error);
        reject(error);
      };
      this.client.onclose = (event) => {
        const { code, reason, wasClean } = event;
        if (code !== 1000) {
          console.error(
            `Socket ${ip} closed. Code: ${code}, reason: ${reason}, was clean: ${wasClean}`,
          );
        }

        this.resolveRead(false);
        this.closed = true;
        if (this.disconnectedCallback) {
          this.disconnectedCallback();
        }
      };
      // CONTEST
      // Seems to not be working, at least in a web worker

      // self.addEventListener("offline", async () => {
      //     await this.close();
      //     this.resolveRead(false);
      // });
    });
  }

  write(data) {
    if (this.closed) {
      throw closeError;
    }
    this.client.send(data);
  }

  async close() {
    await this.client.close();
    this.closed = true;
  }

  receive() {
    this.client.onmessage = async (message) => {
      await mutex.runExclusive(async () => {
        const data =
          message.data instanceof ArrayBuffer
            ? Buffer.from(message.data)
            : Buffer.from(await new Response(message.data).arrayBuffer());
        this.stream = Buffer.concat([this.stream, data]);
        this.resolveRead(true);
      });
    };
  }
}

module.exports = PromisedWebSockets;
