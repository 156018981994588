import { getActions } from "@messenger/global";
import { IS_SAFARI } from "@messenger/util/environment";
import { ElectronEvent } from "@@/src/electron/types";
import { redirectToCall } from "@@/src/app/util/callsRedirect";

type DeepLinkMethod =
  | "resolve"
  | "login"
  | "passport"
  | "settings"
  | "join"
  | "addstickers"
  | "setlanguage"
  | "addtheme"
  | "confirmphone"
  | "socks"
  | "proxy"
  | "privatepost"
  | "bg"
  | "share"
  | "msg"
  | "msg_url";

export const processDeepLink = (url: string) => {
  const { protocol, searchParams, pathname, hostname } = new URL(url);

  if (protocol !== "twl:") return;

  const { openChatByInvite, openStickerSetShortName, focusMessage } =
    getActions();

  const method = (IS_SAFARI ? hostname : pathname).replace(
    /^\/\//,
    "",
  ) as DeepLinkMethod;
  const params: Record<string, string> = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  if (pathname.includes("joincall")) {
    const args = pathname.split("/");
    const hash = args[args.length - 1];
    if (Number.isNaN(parseInt(hash))) {
      redirectToCall(hash, false);
    } else {
      redirectToCall(hash, true);
    }
  }

  switch (method) {
    case "privatepost": {
      const { post, channel } = params;

      focusMessage({
        chatId: `-${channel}`,
        id: post,
      });
      break;
    }
    case "join": {
      const { invite } = params;

      openChatByInvite({ hash: invite });
      break;
    }
    case "addstickers": {
      const { set } = params;

      openStickerSetShortName({
        stickerSetShortName: set,
      });
      break;
    }
    default:
      // Unsupported deeplink
      console.log("Unsupported deeplink", { url });
      break;
  }
};

window.electron?.on(ElectronEvent.DEEPLINK, (deepLink: string) => {
  processDeepLink(deepLink);
});
