import { black, silence } from "./blacksilence";
import type { ApiPhoneCallConnection } from "./types";
import type {
  CandidateMessage,
  MediaStateMessage,
  P2pMessage,
} from "./p2pMessage";
import {
  IS_ECHO_CANCELLATION_SUPPORTED,
  IS_NOISE_SUPPRESSION_SUPPORTED,
  logDTLSState,
  logSDP,
} from "./utils";
import { Conference } from "./buildSdp";
import { StreamType } from "./secretsauce";
import { create } from "zustand";
import { useEffect, useState } from "../teact/teact";
import { debounce } from "@messenger/util/schedulers";

type P2pState = {
  connection: RTCPeerConnection;
  emitSignalingData: (data: P2pMessage) => void;
  onUpdate: (...args: any[]) => void;
  conference?: Partial<Conference>;
  isOutgoing: boolean;
  pendingCandidates: string[];
  streams: {
    video?: MediaStream;
    audio?: MediaStream;
    presentation?: MediaStream;
    ownAudio?: MediaStream;
    ownVideo?: MediaStream;
    ownPresentation?: MediaStream;
  };
  silence: MediaStream;
  blackVideo: MediaStream;
  blackPresentation: MediaStream;
  mediaState: Omit<MediaStateMessage, "@type">;
  audio: HTMLAudioElement;
  gotInitialSetup?: boolean;
  facingMode?: VideoFacingModeEnum;
};

let state: P2pState | undefined;

const ICE_CANDIDATE_POOL_SIZE = 10;

export function getStreams() {
  return state?.streams;
}

export function updateVideo(value: boolean) {
  state?.emitSignalingData({
    "@type": "media",
    data: {
      video: value,
    },
  });
}

function updateStreams() {
  state?.onUpdate({
    ...state.mediaState,
    "@type": "updatePhoneCallMediaState",
  });
}

function updateConnectionState(connectionState: RTCIceConnectionState) {
  state?.onUpdate({
    "@type": "updatePhoneCallConnectionState",
    connectionState,
  });
}

function getUserStream(
  streamType: StreamType,
  facing: VideoFacingModeEnum = "user",
): Promise<MediaStream> {
  if (streamType === "presentation") {
    return (navigator.mediaDevices as any).getDisplayMedia({
      audio: false,
      video: true,
    });
  }

  return navigator.mediaDevices.getUserMedia({
    audio:
      streamType === "audio"
        ? {
            echoCancellation: true,
            noiseSuppression: true,
          }
        : false,
    video:
      streamType === "video"
        ? {
            facingMode: facing,
          }
        : false,
  });
}

export async function switchCameraInputP2p() {
  if (!state || !state.facingMode) {
    return;
  }

  const stream = state.streams.ownVideo;

  if (!stream) return;

  const track = stream.getTracks()[0];

  if (!track) {
    return;
  }

  const sender = state.connection
    .getSenders()
    .find((l) => track.id === l.track?.id);

  if (!sender) {
    return;
  }

  state.facingMode =
    state.facingMode === "environment" ? "user" : "environment";
  try {
    const newStream = await getUserStream("video", state.facingMode);

    await sender.replaceTrack(newStream.getTracks()[0]);
    state.streams.ownVideo = newStream;
    updateStreams();
  } catch (e) {
    console.error({ e });
  }
}

let audioContext: AudioContext | undefined;
let analyser: AnalyserNode | undefined;
let sourceNode: MediaStreamAudioSourceNode | undefined;
let scriptProcessor: ScriptProcessorNode | undefined;

export async function toggleStreamP2p(
  streamType: StreamType,
  value: boolean | undefined = undefined,
) {
  if (!state) return;
  const stream =
    streamType === "audio"
      ? state.streams.ownAudio
      : streamType === "video"
        ? state.streams.ownVideo
        : state.streams.ownPresentation;

  if (!stream) return;
  const track = stream.getTracks()[0];

  if (!track) {
    return;
  }

  const sender = state.connection
    .getSenders()
    .find((l) => track.id === l.track?.id);

  if (!sender) {
    return;
  }

  value = value === undefined ? !track.enabled : value;

  try {
    if (value && !track.enabled) {
      const newStream = await getUserStream(streamType);
      newStream.getTracks()[0].onended = () => {
        toggleStreamP2p(streamType, false);
      };
      await sender.replaceTrack(newStream.getTracks()[0]);

      if (streamType === "audio") {
        state.streams.ownAudio = newStream;
        startAudioAnalyzer(newStream);
      } else if (streamType === "video") {
        state.streams.ownVideo = newStream;
        state.facingMode = "user";
      } else {
        state.streams.ownPresentation = newStream;
      }

      if (streamType === "video" || streamType === "presentation") {
        toggleStreamP2p(
          streamType === "video" ? "presentation" : "video",
          false,
        );
      }
    } else if (!value && track.enabled) {
      track.stop();
      const newStream =
        streamType === "audio"
          ? state.silence
          : streamType === "video"
            ? state.blackVideo
            : state.blackPresentation;
      if (!newStream) return;

      await sender.replaceTrack(newStream.getTracks()[0]);

      if (streamType === "audio") {
        state.streams.ownAudio = newStream;
        stopAudioAnalyzer();
      } else if (streamType === "video") {
        state.streams.ownVideo = newStream;
      } else {
        state.streams.ownPresentation = newStream;
      }
    }
    updateStreams();
    sendMediaState();
  } catch (err) {
    console.error(err);
  }
}

export async function joinPhoneCall(
  connections: ApiPhoneCallConnection[],
  emitSignalingData: (data: P2pMessage) => void,
  isOutgoing: boolean,
  shouldStartVideo: boolean,
  isP2p: boolean,
  onUpdate: (...args: any[]) => void,
) {
  const conn = new RTCPeerConnection({
    iceServers: connections.map((connection) => {
      return {
        urls: !!connection.ipv6
          ? [
              connection.isTurn &&
                `turn:[${connection.ipv6}]:${connection.port}`,
              connection.isStun &&
                `stun:[${connection.ipv6}]:${connection.port}`,
            ].filter(Boolean)
          : [
              connection.isTurn && `turn:${connection.ip}:${connection.port}`,
              connection.isStun && `stun:${connection.ip}:${connection.port}`,
            ].filter(Boolean),
        username: connection.username,
        credentialType: "password",
        credential: connection.password,
      };
    }),
    iceTransportPolicy: isP2p ? "all" : "relay",
    bundlePolicy: "max-bundle",
    iceCandidatePoolSize: ICE_CANDIDATE_POOL_SIZE,
  });

  conn.onicecandidate = (e) => {
    if (!e.candidate) {
      return;
    }
    emitSignalingData({
      "@type": "candidate",
      data: {
        sdp: e.candidate.candidate,
        sdpMid: e.candidate.sdpMid,
        mid: parseInt(e.candidate.sdpMid ?? "0"),
      },
    });
  };

  conn.onconnectionstatechange = () => {};

  conn.ontrack = (e) => {
    if (!state) return;
    const stream = e.streams[0];

    if (e.track.kind === "audio") {
      state.audio.srcObject = stream;
      state.audio.play().catch();
      state.streams.audio = stream;
    } else if (e.transceiver.mid === "1") {
      state.streams.video = stream;
    } else {
      state.streams.presentation = stream;
    }

    updateStreams();
  };

  conn.oniceconnectionstatechange = async (e) => {
    updateConnectionState(conn.iceConnectionState);
    logDTLSState("ICE Connection State", conn.iceConnectionState);
    switch (conn.iceConnectionState) {
      case "disconnected":
      case "failed":
        if (isOutgoing) {
          await createOffer(conn, {
            offerToReceiveAudio: true,
            offerToReceiveVideo: shouldStartVideo,
            iceRestart: true,
          });
        }
        break;
      default:
        break;
    }
  };

  const slnc = silence(new AudioContext());
  const video = black({ width: 640, height: 480 });
  //TODO: return presentation when we can
  conn.addTrack(slnc.getTracks()[0], slnc);
  conn.addTrack(video.getTracks()[0], video);
  //TODO: return presentation when we can

  const audio = new Audio();

  state = {
    audio,
    connection: conn,
    emitSignalingData,
    isOutgoing,
    pendingCandidates: [],
    onUpdate,
    streams: {
      ownVideo: video,
      ownAudio: slnc,
      //TODO: return presentation when we can
      ownPresentation: undefined,
    },
    mediaState: {
      isBatteryLow: false,
      screencastState: "inactive",
      videoState: "inactive",
      videoRotation: 0,
      isMuted: true,
    },
    blackVideo: video,
    //TODO: return presentation when we can
    blackPresentation: black(),
    silence: slnc,
  };

  try {
    toggleStreamP2p("audio", true);
    if (!!window.electron) {
      // for electron it's better to request permissions on camera, since in other case toggle may be broken
      const camPermission = await navigator.permissions.query({
        name: "camera" as PermissionName,
      });
      console.log({ camPermission });
    }
  } catch (err) {
    console.error(err);
  }

  if (isOutgoing) {
    await createOffer(conn, {
      offerToReceiveAudio: true,
      offerToReceiveVideo: true,
    });
  }
}

export function stopPhoneCall() {
  if (!state) return;

  stopAudioAnalyzer();
  state.streams.ownVideo?.getTracks().forEach((track) => track.stop());
  state.streams.ownPresentation?.getTracks().forEach((track) => track.stop());
  state.streams.ownAudio?.getTracks().forEach((track) => track.stop());
  state.connection.close();
  state = undefined;
}

function sendMediaState() {
  if (!state) return;
  const { emitSignalingData, streams } = state;

  emitSignalingData({
    "@type": "MediaState",
    videoRotation: 0,
    isMuted: !streams.ownAudio?.getTracks()[0].enabled,
    isBatteryLow: true,
    videoState: streams.ownVideo?.getTracks()[0].enabled
      ? "active"
      : "inactive",
    screencastState: streams.ownPresentation?.getTracks()[0].enabled
      ? "active"
      : "inactive",
  });
}

function sendInitialSetup(
  sdp: RTCSessionDescriptionInit,
  params: RTCOfferOptions,
) {
  if (!state) return;
  const { emitSignalingData } = state;

  state.gotInitialSetup = true;
  emitSignalingData({
    "@type": "sdp",
    data: {
      sdp: sdp.sdp!!,
      type: sdp.type,
      video: params.offerToReceiveVideo ?? false,
    },
  });
}

export async function processSignalingMessage(message: P2pMessage) {
  if (!state || !state.connection) return;
  switch (message["@type"]) {
    default:
      console.error("unsupported signaling message with @type", message);
      break;
    case "media":
      state.mediaState.videoState = message.data.video ? "active" : "inactive";
      updateStreams();
      sendMediaState();
      break;
    case "candidate":
      await tryAddCandidate(state.connection, message);
      break;
    case "sdp":
      logSDP("Received SDP", message.data.sdp || "");
      state.mediaState.videoState = message.data.video ? "active" : "inactive";
      setRemoteDescriptionAndAddCandidates(
        state.connection,
        new RTCSessionDescription({
          sdp: message.data.sdp,
          type: message.data.type,
        }),
      );
      if (message.data.type == "offer") {
        state.connection.createAnswer().then((answer) => {
          logSDP("Generated Answer SDP", answer.sdp!);
          state?.connection.setLocalDescription(answer);
          state?.emitSignalingData({
            "@type": "sdp",
            data: {
              sdp: answer.sdp!,
              type: answer.type,
              video: message.data.video,
            },
          });
        });
      }
      break;
  }
}

async function tryAddCandidate(
  connection: RTCPeerConnection,
  msg: CandidateMessage,
) {
  try {
    await connection.addIceCandidate({
      candidate: msg.data.sdp,
      sdpMid: msg.data.sdpMid,
      sdpMLineIndex: msg.data.mid,
    });
  } catch (err) {
    console.error(err);
  }
}

async function createOffer(conn: RTCPeerConnection, params: RTCOfferOptions) {
  const offer = await conn.createOffer(params);
  await conn.setLocalDescription(offer);
  sendInitialSetup(offer, params);
}

function startAudioAnalyzer(stream: MediaStream) {
  if (audioContext) {
    console.warn("Audio analyzer is already running.");
    return;
  }

  audioContext = new AudioContext();
  analyser = audioContext.createAnalyser();
  analyser.fftSize = 512;

  sourceNode = audioContext.createMediaStreamSource(stream);
  scriptProcessor = audioContext.createScriptProcessor(512, 1, 1);

  sourceNode.connect(analyser);
  analyser.connect(scriptProcessor);
  scriptProcessor.connect(audioContext.destination);

  scriptProcessor.onaudioprocess = processAudioAmplitude;

  console.log("🎤 Audio Analyzer Started!");
}

function processAudioAmplitude(event: AudioProcessingEvent) {
  const inputBuffer = event.inputBuffer;
  const rawData = inputBuffer.getChannelData(0);

  let sum = 0;
  for (let i = 0; i < rawData.length; i++) {
    sum += rawData[i] ** 2;
  }
  let amplitude = Math.sqrt(sum / rawData.length);

  if (amplitude < 0.0001) {
    amplitude = 0;
  }

  const normalizedAmplitude = Math.min(amplitude * 10, 1);

  p2pStore.setState({ amplitude: normalizedAmplitude });
}

function stopAudioAnalyzer() {
  if (scriptProcessor) {
    scriptProcessor.disconnect();
    scriptProcessor.onaudioprocess = null;
    scriptProcessor = undefined;
  }
  if (sourceNode) {
    sourceNode.disconnect();
    sourceNode = undefined;
  }
  if (analyser) {
    analyser.disconnect();
    analyser = undefined;
  }
  if (audioContext) {
    audioContext.close();
    audioContext = undefined;
  }
  console.log("🛑 Audio Analyzer Stopped.");
}

async function setRemoteDescriptionAndAddCandidates(
  connection: RTCPeerConnection,
  sdp: RTCSessionDescriptionInit,
) {
  try {
    if (!sdp.sdp) {
      console.error("SDP is undefined");
      return;
    }
    await connection.setRemoteDescription(sdp);

    const lines = sdp.sdp.split("\r\n");
    const candidates = lines.filter((line) => line.startsWith("a=candidate:"));

    for (const candidate of candidates) {
      try {
        await connection.addIceCandidate(new RTCIceCandidate({ candidate }));
      } catch (err) {
        console.error("Error adding ICE candidate:", err);
      }
    }
  } catch (err) {
    console.error("Error setting remote description:", err);
  }
}

const p2pStore = create(() => {
  return {
    amplitude: 0,
  };
});

export const useP2pStore = () => {
  const [state, setState] = useState(p2pStore.getState());
  useEffect(() => {
    return p2pStore.subscribe((state) => {
      setState(state);
    });
  }, []);

  return state;
};
