import "@messenger/util/handleError";
import "@messenger/util/setupServiceWorker";

import React from "@messenger/lib/teact/teact";
import TeactDOM from "@messenger/lib/teact/teact-dom";

import { getActions, getGlobal } from "@messenger/global";
import "@messenger/global/init";
import { requestPermission } from "@tauri-apps/plugin-notification";
import { DEBUG } from "@messenger/config";

import App from "@messenger/App";

import "@messenger/styles/index.scss";
import { SentryManager } from "@messenger/sentry";
import { createCaptcha } from "@@/src/app/components/captcha";

if (DEBUG) {
  console.log(">>> INIT");
}

try {
  requestPermission();
} catch (e) {
  console.error(e);
}

getActions().init();

if (DEBUG) {
  console.log(">>> START INITIAL RENDER");
}

console.log("DEBUG: sentry initial", {
  enabled: SentryManager.clientOptions.enabled,
  environment: SentryManager.clientOptions.environment,
  globalScope: SentryManager.globalScope,
});

window.electron?.restoreLocalStorage();
TeactDOM.render(<App />, document.getElementById("root"));

if (DEBUG) {
  console.log(">>> FINISH INITIAL RENDER!");
}

if (DEBUG) {
  document.addEventListener("dblclick", () => {
    console.warn("GLOBAL STATE", getGlobal());
  });
}

createCaptcha();
