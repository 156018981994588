import AuthBrand from "@messenger/components/auth/AuthBrand";
import Button from "@messenger/components/ui/Button";
import { cn } from "@messenger/shared/styles";
import QrCreator from "qr-creator";
import React, {
  type FC,
  useEffect,
  useRef,
  memo,
  useCallback,
} from "@messenger/lib/teact/teact";
import { getActions, withGlobal } from "@messenger/global";

import type { GlobalState } from "@messenger/global/types";
import type { LangCode } from "@messenger/types";

import { appsConfig, DEFAULT_LANG_CODE, IS_PRODUCTION } from "@messenger/config";
import { setLanguage } from "@messenger/util/langProvider";
import renderText from "@messenger/components/common/helpers/renderText";
// import useLangString from '@messenger/hooks/useLangString';
import useFlag from "@messenger/hooks/useFlag";
import useLang from "@messenger/hooks/useLang";
import { getSuggestedLanguage } from "@messenger/components/auth/helpers/getSuggestedLanguage";

import Loading from "@messenger/components/ui/Loading";

import * as authCommonStyles from "@messenger/components/auth/styles.module.scss";
import * as styles from "@messenger/components/auth/AuthQrCode.module.scss";

type StateProps = Pick<
  GlobalState,
  "connectionState" | "authState" | "authQrCode"
> & {
  language?: LangCode;
};

const DATA_PREFIX = `${appsConfig.prefix}://login?token=`;

const AuthCode: FC<StateProps> = ({
  connectionState,
  authState,
  authQrCode,
  language,
}) => {
  const { returnToAuthPhoneNumber, setSettingOption } = getActions();

  const suggestedLanguage = getSuggestedLanguage();
  const lang = useLang();

  const qrCodeRef = useRef<HTMLDivElement>(null);
  const [isLoading, markIsLoading, unmarkIsLoading] = useFlag();

  const productName = appsConfig.name
  useEffect(() => {
    if (!authQrCode || connectionState !== "connectionStateReady") return;

    const container = qrCodeRef.current;
    if (!container) return;

    container.innerHTML = "";
    container.classList.remove("pre-animate");

    QrCreator.render(
      {
        text: `${DATA_PREFIX}${authQrCode.token}`,
        radius: 0.5,
        ecLevel: "H",
        size: 280 * 4,
        top: 10,
        fill: "black",
      },
      container,
    );
  }, [connectionState, authQrCode]);

  useEffect(() => {
    if (connectionState === "connectionStateReady") {
      void setLanguage(DEFAULT_LANG_CODE);
    }
  }, [connectionState]);

  const isAuthReady = authState === "authorizationStateWaitQrCode";

  return (
    <div className={authCommonStyles.page}>
      <AuthBrand />

      <section className={authCommonStyles.halfPageWrapper}>
        <div
          className={cn(authCommonStyles.halfPageContainer, styles.container)}
        >
          <div className={cn("custom-scroll", styles.qrForm)}>
            {authQrCode ? (
              <div
                key="qr-container"
                className={cn(
                  styles.qrContainer,
                  "pre-animate p-2 pb-0 flex justify-center items-center bg-white rounded-3xl",
                )}
                ref={qrCodeRef}
              />
            ) : (
              <div key="qr-loading" className={cn(styles.qrLoading)}>
                <Loading />
              </div>
            )}

            <h3>{lang("Login.QR.Title", productName)}</h3>

            <ol>
              <li>
                <span>{lang("Login.QR.Help1", productName)}</span>
              </li>
              <li>
                <span>
                  {renderText(lang("Login.QR.Help2"), ["simple_markdown"])}
                </span>
              </li>
              <li>
                <span>{lang("Login.QR.Help3")}</span>
              </li>
            </ol>

            {isAuthReady && !IS_PRODUCTION && (
              <Button isText onClick={returnToAuthPhoneNumber}>
                {lang("Login.QR.Cancel")}
              </Button>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default memo(
  withGlobal((global): StateProps => {
    const {
      connectionState,
      authState,
      authQrCode,
      settings: {
        byKey: { language },
      },
    } = global;

    return {
      connectionState,
      authState,
      authQrCode,
      language,
    };
  })(AuthCode),
);
